.modal__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem;
  outline: none;
}

.modal__box .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__box .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__box .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__box .modal__body {
  margin-bottom: 2rem;
}

.modal__box .modal__body p {
  font-family: var(--font-mono-regular);
}

.modal__box .modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
}

.modal__box .modal__footer button {
  padding: 1rem;
  font-family: var(--font-mono-regular);
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.modal__box .modal__footer button.confirm {
  background-color: var(--green);
}

.modal__box .modal__footer button.cancel {
  background-color: var(--red);
}

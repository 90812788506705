.docs label,
.docs input,
.docs input::placeholder {
  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);
}

main {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f3f3;

  padding-bottom: 6rem;
}

main .container {
  max-width: 1180px;

  margin: 0 auto;
}

main .container .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 0.75rem;
  margin-bottom: 2.5rem;
}

main .container .header .title {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

main .container .header .title h5 {
  font-family: var(--font-mono-regular);
  font-size: 1.75rem;
  color: var(--ultra-light-gray);
}

main .container .header h1 {
  font-family: var(--font-bold);
  font-size: 3rem;
  color: var(--ultra-light-gray);
}

main .container .tables {
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 600px;
  column-gap: 1rem;
}

main .container .tables .box {
  border: 1px solid #d8d8d8;
  background-color: #fff;
}

main .container .tables .main {
  width: 100%;
}

main .container .tables .main .buttons {
  height: 50px;
  width: 100%;

  background-color: var(--ultra-light-gray);

  display: flex;
  flex-direction: row;
}

main .container .tables .main .buttons .btn {
  width: 190px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d8d8d8;
}

main .container .tables .main .buttons .btn h3 {
  font-family: var(--font-bold);
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
}

main .container .tables .main .content {
  padding: 2rem;
  width: 100%;

  overflow-x: auto;
}

main .container .tables .main .content .years__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1px;

  margin-bottom: 2rem;
}

main .container .tables .main .content .years__buttons .years {
  display: flex;
}

main .container .tables .main .content .years__buttons .years button {
  padding: 6px 16px;
  background: none;
  border: 1px solid #d8d8d8;

  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);

  display: flex;
  justify-items: center;
  align-items: center;

  margin-right: 1px;

  cursor: pointer;
}

main .container .tables .main .content .years__buttons .downloads button {
  padding: 6px;
  background: #525252;
  border: 1px solid #525252;

  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);

  display: flex;
  justify-items: center;
  align-items: center;

  margin-right: 1px;

  cursor: pointer;
}

main .container .tables .main .content .years__buttons button.checked {
  background-color: #525252;
  color: #fff;
}

main .container .tables .main .content .years__buttons button:hover {
  background-color: #525252;
  transition: 100ms all;
  color: #fff;
}

main .container .tables .main .content .years__buttons .downloads .button__loading {
  cursor: not-allowed;
  pointer-events: none;
  position: relative;
}

main .container .tables .main .content .years__buttons .downloads .button__loading img {
  visibility: hidden;
  opacity: 0;
}

main .container .tables .main .content .years__buttons .downloads .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
/* 
main .container .tables .main .content .months__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

main .container .tables .main .content .months__list .month {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
}

main .container .tables .main .content .months__list .month:hover {
  cursor: pointer;
  background-color: #f2f3f3;
}

main .container .tables .main .content .months__list .month .title {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

main .container .tables .main .content .months__list .month .title h1 {
  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);
}

main .container .tables .main .content .months__list .month .title h1.disabled {
  color: #c5c5c5;
}

main .container .tables .main .content .months__list .month .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  margin: 0;
}

main .container .tables .main .content .months__list .month .actions a {
  display: flex;
  justify-content: center;
  align-items: center;
}

main .container .tables .main .content .months__list .month .actions .loading {
  width: 24px;
  height: 24px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
} */

main .container .tables .stats {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

main .container .tables .stats .info {
  width: 100%;
}

main .container .tables .stats .info .body {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 40px;

  padding: 22px 30px;

  text-align: center;

  row-gap: 40px;
}

main .container .tables .stats .info .body .numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 44px;
  row-gap: 1rem;
}

main .container .tables .stats .info .body .numbers .type h5 {
  font-size: 1.5rem;
}

main .container .tables .stats .info .body .numbers .type h4 {
  font-size: 2rem;
}

main .container .tables .stats .nun {
  width: 100%;
}

main .container .tables .stats .nun .body {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.5rem;
  padding: 20px;
}

main .container .tables .stats .nun .body img {
  margin: 0 auto;
}

main .container .tables .stats .nun .body .body__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

main .container .tables .stats .nun .body .body__info p {
  font-family: var(--font-regular);
  color: var(--ultra-light-gray);

  margin-bottom: 8px;
}

main .container .tables .stats .nun .body .body__info a {
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 8px;

  font-family: var(--font-bold);
  color: #0088c2;
  text-decoration: none;
}

main .container .tables .stats .box__info__header {
  display: flex;
  align-items: center;

  width: 100%;
  height: 42px;

  padding: 0 1rem;

  background-color: var(--ultra-light-gray);
}

main .container .tables .stats .box__info__header h1 {
  font-family: var(--font-bold);
  font-size: 1rem;
  color: #fff;
}

main #table {
  position: absolute;
  display: none;
  left: -1000px;
  top: -1200px;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 1280px) {
  main .container .tables {
    padding: 0 10px;
  }
}

@media (max-width: 940px) {
  main .container .tables {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  main .container .tables .main .box {
    max-width: 768px;
  }
}

@media (max-width: 375px) {
  main .container .tables .main .content {
    padding: 1rem;
  }
}

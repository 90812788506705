.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  column-gap: 100px;

  min-height: 100vh;
}

.signup .texts.md-screen {
  display: flex;
}

.signup .texts.sm-screen {
  display: none;
}

.signup .form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup .form .form__box {
  background-color: #f4f4f4;
  border-radius: 1px;
  padding: 60px;
}

.signup .form .form__box form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.signup .form .form__box form .input__box {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;

  margin-bottom: 1rem;

  column-gap: 12px;
}

.signup .form .form__box form .input__box input {
  outline: none;
  border: none;
  background: none;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;
}

.signup .form .form__box form .input__box input::placeholder {
  font-family: var(--font-bold);
  color: #fff;
}

.signup .form .form__box form .input__box input:-webkit-autofill,
.signup .form .form__box form .input__box input:-webkit-autofill:hover,
.signup .form .form__box form .input__box input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px var(--ultra-light-gray) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.signup .form .form__box form button {
  background-color: var(--green);
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;

  width: 100%;
  height: 65px;

  cursor: pointer;

  position: relative;

  margin-top: 4rem;
}

.signup .form .form__box form .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.signup .form .form__box form .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.signup .texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 650px;
}

.signup .texts h4 {
  font-family: var(--font-mono-regular);
  font-size: 2rem;
  max-width: 500px;
  color: var(--ultra-light-gray);
}

.signup .texts a {
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 1.75rem;

  text-decoration: none;
  color: var(--ultra-light-gray);

  font-family: var(--font-bold);
  padding: 8px 12px;
  transition: 0.5s all;
}

.signup .texts a:hover {
  background-color: #fafafa;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 1024px) {
  .signup {
    padding: 80px 0px;
  }

  .signup .texts.md-screen {
    display: none;
  }

  .signup .texts.sm-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 450px;
    padding: 20px 0;
    margin-bottom: 2rem;
  }
}

.client {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 0.75rem;
  margin-bottom: 2.5rem;
}

.client .back__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  padding: 4px 12px;
  cursor: pointer;
  transition: 0.5s all;
  border-radius: 1px;
}

.client .back__menu:hover {
  background-color: #e5e5e5;
}

.client .back__menu span {
  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);
}

.client .title {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.client .title h5 {
  font-family: var(--font-mono-regular);
  font-size: 1.75rem;
  color: var(--ultra-light-gray);
}

.client .title button {
  background: none;
  border: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.client h1 {
  font-family: var(--font-bold);
  font-size: 3rem;
  color: var(--ultra-light-gray);
  max-width: 700px;
}

@media (max-width: 1024px) {
  .client {
    padding: 0 20px;
    max-width: calc(100vw - 10vw);
  }
}

@media (max-width: 375px) {
  .client h1 {
    font-size: 2.25rem;
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

@media (max-width: 320px) {
  .client h1 {
    font-size: 1.8rem;
  }
}

.recover__password {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.recover__password form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 344px;

  row-gap: 1.25rem;
}

.recover__password form h1 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  text-align: center;
  color: var(--ultra-light-gray);
}

.recover__password form .logo__app {
  width: 324px;

  margin-bottom: 74px;
}

.recover__password form .input__box {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;
}

.recover__password form .input__box input {
  outline: none;
  border: none;
  background: none;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;
}

.recover__password form .input__box input::placeholder {
  font-family: var(--font-bold);
  color: #fff;
}

.recover__password form button {
  background-color: var(--green);
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;

  width: 100%;
  height: 65px;

  cursor: pointer;

  position: relative;

  margin-bottom: 1rem;
}

.recover__password form a {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: var(--ultra-light-gray);

  text-decoration: none;
  column-gap: 28px;
  width: 100%;

  padding: 1rem;

  transition: 200ms linear all;
}

.recover__password form hr {
  width: 100%;
  height: 1px;
  background-color: var(--ultra-light-gray);
  border: none;
}

.recover__password .create__account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.3rem;
}

.recover__password .create__account h5,
.recover__password .create__account a {
  font-family: var(--font-bold);
  color: #fff;
}

.recover__password form .button__loading {
  cursor: not-allowed;
  pointer-events: none;
}

.recover__password form .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.recover__password form .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.modal__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem;
}

.modal__box .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__box .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__box .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__box .modal__body h1 {
  font-family: var(--font-mono-regular);
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
}

.modal__box .modal__body a {
  font-family: var(--font-mono-regular);
  font-weight: 400;
  text-align: center;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.modal__box .modal__body .no__clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal__box .modal__body .acc_client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  border: 1px solid #f2f3f3;
  padding: 0 0 0 1rem;
}

.modal__box .modal__body .acc_client h3 {
  font-family: var(--font-mono-regular);
}

.modal__box .modal__body .acc_client button {
  border: none;
  outline: none;
  padding: 1rem;
  font-family: var(--font-mono-regular);
  font-weight: bold;
  cursor: pointer;
  background-color: var(--green);
  color: #fff;
}

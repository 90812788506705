.month {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
}

.month:hover {
  cursor: pointer;
  background-color: #f2f3f3;
}

.month .title {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.month .title h1 {
  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);
}

.month .title h1.disabled {
  color: #c5c5c5;
}

.month .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  margin: 0;
}

.month .actions a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.month .actions img {
  width: 24px;
  height: 24px;
}

.month .actions .loading {
  width: 24px;
  height: 24px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.month table {
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 500px) {
  .month .title h1 {
    font-size: 0.75rem !important;
  }

  .month .title {
    column-gap: 0.5rem;
  }

  .month .actions img {
    width: 20px;
    height: 20px;
  }
}

.modal__box__requests {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem;
}

.modal__box__requests .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__box__requests .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__box__requests .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__box__requests .modal__body h3 {
  font-family: var(--font-bold);
  font-weight: 400;
  color: var(--ultra-light-gray);
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.modal__box__requests .modal__body p {
  font-family: var(--font-regular);
  font-weight: 400;
  color: var(--ultra-light-gray);
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.modal__box__requests .modal__body .modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__box__requests .modal__body .modal__footer span {
  padding: 8px;
  font-family: var(--font-bold);
  border-radius: 2px;
  color: var(--ultra-light-gray);
  background-color: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.modal__box__requests .modal__body .modal__footer span:hover {
  background-color: #fafafa;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 500px) {
  .modal__box__requests {
    width: 400px !important;
  }
}

@media (max-width: 375px) {
  .modal__box__requests {
    width: 355px !important;
  }

  .modal__box__requests .modal__body table tbody tr td {
    font-size: 0.86rem !important;
  }
}

@media (max-width: 320px) {
  .modal__box__requests {
    width: 300px !important;
    padding: 1rem !important;
  }

  .modal__box__requests .modal__body table thead tr th {
    font-size: 0.7rem !important;
  }

  .modal__box__requests .modal__body table tbody tr td {
    font-size: 0.7rem !important;
  }
}

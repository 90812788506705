header {
  width: 100%;
  height: 70px;

  background-color: var(--ultra-light-gray);
  margin-bottom: 3rem;
}

header .container {
  max-width: 1360px;
  height: 100%;

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0rem 3rem !important;
}

header .container .sm-logo {
  display: none;

  width: 50px;
}

header .container .md-logo {
  width: 250px;
}

header .container .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

header .container .buttons img {
  cursor: pointer;
}

header .container .buttons .sm-exit-button {
  display: none;

  margin-left: 2rem;
}

@media (max-width: 865px) {
  header .container .sm-logo {
    display: block;
  }

  header .container .md-logo {
    display: none;
  }
}

@media (max-width: 560px) {
  header .container .buttons .md-exit-button {
    display: none;
  }

  header .container .buttons .sm-exit-button {
    display: block;
  }
}

@media (max-width: 560px) {
  header .container .buttons img {
    width: 40px;
  }
}

@media (max-width: 375px) {
  header .container {
    padding: 0 16px !important;
  }
}

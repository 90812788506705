.modal__change__username {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem 2rem 1.5rem;

  font-family: var(--font-mono-regular);
  font-weight: 400;
}

.modal__change__username .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__change__username .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__change__username .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__change__username .modal__body {
  width: 100%;
}

.modal__change__username .modal__body form {
  font-family: var(--font-mono-regular);
  font-weight: 400;
}

.modal__change__username .modal__body form input {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  padding: 1rem;
  height: 56px;

  font-family: inherit;
  font-weight: inherit;

  margin-bottom: 2rem;
}

.modal__change__username .modal__body .user__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.modal__change__username .modal__body .user__details h1 {
  font-family: inherit;
  font-weight: inherit;

  text-align: center;
}

.modal__change__username .modal__body .actions h5 {
  font-family: inherit;
  font-weight: inherit;
  font-size: 0.9rem;
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;
}

.modal__change__username .modal__body table {
  width: 100%;

  font-family: var(--font-mono-regular);
}

.modal__change__username .modal__body table tbody:before {
  padding-left: 1rem;
  line-height: 0.5rem;
  content: '.';
  color: white;
  display: block;
}

.modal__change__username .modal__body table tbody tr td.flex {
  display: flex;
  align-items: center;
}

.modal__change__username .modal__body table tbody tr td svg {
  cursor: pointer;
}

.modal__change__username .modal__body form .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
}

.modal__change__username .modal__body form .buttons button {
  padding: 1rem;
  font-family: var(--font-mono-regular);
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.modal__change__username .modal__body form .buttons .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.modal__change__username .modal__body form .buttons .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.modal__change__username .modal__body form .buttons button.confirm {
  background-color: var(--green);
}

.modal__change__username .modal__body form .buttons button.cancel {
  background-color: var(--red);
}

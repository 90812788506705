.modal__profile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem 2rem 1.5rem;

  font-family: var(--font-mono-regular);
  font-weight: 400;
}

.modal__profile .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__profile .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__profile .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__profile .modal__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.modal__profile .modal__body .user__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.modal__profile .modal__body .user__details h1 {
  font-family: inherit;
  font-weight: inherit;

  text-align: center;
}

.modal__profile .modal__body .actions h5 {
  font-family: inherit;
  font-weight: inherit;
  font-size: 0.9rem;
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;
}

.modal__profile .modal__body table {
  width: 100%;

  font-family: var(--font-mono-regular);
}

.modal__profile .modal__body table tbody:before {
  padding-left: 1rem;
  line-height: 0.5rem;
  content: '.';
  color: white;
  display: block;
}

.modal__profile .modal__body table tbody tr td.flex {
  display: flex;
  align-items: center;
}

.modal__profile .modal__body table tbody tr td svg {
  cursor: pointer;
}

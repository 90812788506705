.table__header {
  height: 50px;
  width: 100%;

  background-color: var(--ultra-light-gray);

  display: flex;
  flex-direction: row;
}

.table__header .btn {
  height: 100%;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  cursor: pointer;
}

.table__header .btn h3 {
  font-family: var(--font-bold);
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
}

.table__header .selected {
  background-color: #808080;
}

@media (max-width: 425px) {
  .table__header {
    max-width: calc(100vw - 5vw) !important;
  }
}

@media (max-width: 590px) {
  .table__header {
    height: 70px;

    max-width: calc(100vw - 4vw);
  }
}

@media (max-width: 680px) {
  .table__header {
    overflow-x: scroll !important;
  }
}

.modal__box__requests {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 1rem 1.5rem;
}

.modal__box__requests .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.modal__box__requests .modal__header h1 {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 1.5rem;
}

.modal__box__requests .modal__header img {
  width: 32px;
  cursor: pointer;
}

.modal__box__requests .modal__body .request .loading {
  width: 32px;
  height: 32px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.modal__box__requests .modal__body table {
  width: 100%;

  font-family: var(--font-mono-regular);

  margin-bottom: 2rem;
}

.modal__box__requests .modal__body table tbody:before {
  padding-left: 1rem;
  line-height: 0.5rem;
  content: '.';
  color: white;
  display: block;
}

.modal__box__requests .modal__body table tbody tr td.flex {
  display: flex;
  align-items: center;
}

.modal__box__requests .modal__body table tbody tr td a {
  text-decoration: none;
  color: black;
}

.modal__box__requests .modal__body table tbody tr td svg {
  cursor: pointer;
}

.modal__box__requests .modal__body .request .table__footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__box__requests .modal__body .table__warning {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding: 2rem 0;
}

.modal__box__requests .modal__body .table__warning h1 {
  font-family: var(--font-mono-regular);
  font-weight: 400;
  color: #8a8a8a;
  font-size: 1.125rem;
  text-align: center;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 500px) {
  .modal__box__requests {
    width: 400px !important;
  }
}

@media (max-width: 375px) {
  .modal__box__requests {
    width: 355px !important;
  }

  .modal__box__requests .modal__body table tbody tr td {
    font-size: 0.86rem !important;
  }
}

@media (max-width: 320px) {
  .modal__box__requests {
    width: 300px !important;
    padding: 1rem !important;
  }

  .modal__box__requests .modal__body table thead tr th {
    font-size: 0.7rem !important;
  }

  .modal__box__requests .modal__body table tbody tr td {
    font-size: 0.7rem !important;
  }
}

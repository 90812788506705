.recovered__password {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.recovered__password .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 500px;

  row-gap: 4rem;

  padding: 4rem 0.5rem;
}

.recovered__password .container h1 {
  font-family: var(--font-bold);
  font-size: 2rem;
  text-align: center;
  color: #fff;
}

.recovered__password .container .confirmed {
  background-color: var(--green);
  border-radius: 8px;

  border: none;
  outline: none;

  width: 100%;
  height: 90px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;

  padding: 0 1rem;
}

.recovered__password .container .confirmed h5 {
  font-family: var(--font-bold);
  font-size: 1.5em;
  color: #fff;
}

.recovered__password .container a {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: var(--ultra-light-gray);

  text-decoration: none;
  column-gap: 28px;
  width: 100%;

  padding: 1rem;
}
